/* eslint-disable react/no-unescaped-entities */
import { Image } from 'src/components/ui/Image'
import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const CupomDesconto = () => {
  return (
    <>
      <section className="section-services coupon-discount_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar CupomDesconto-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- CupomDesconto Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center">
                    CUPOM DE DESCONTO
                  </h1>
                  <h2 className="text-gray italic text-xl font-semibold text-left">
                    POLÍTICAS DOS CUPONS DE DESCONTOS
                  </h2>
                  <p className="text-left text-sm text-gray mt-4">
                    {' '}
                    As promoções vigentes da loja decathlon.com.br são
                    exclusivas deste canal de vendas internet e não são válidas
                    para nossas lojas físicas.
                  </p>
                  <h3 className="text-left text-sm font-semibold">
                    Importante:
                  </h3>
                  <ol className="text-left text-sm text-gray ml-10">
                    <li>
                      1. Confira a data de validade da promoção participante.
                    </li>
                    <li>2. As promoções não são cumulativas. </li>
                    <li>
                      3. Dúvidas:
                      <a
                        href="www.decathlon.com.br/atendimento"
                        className="text-blue"
                        target="_blank"
                      >
                        www.decathlon.com.br/atendimento
                      </a>
                    </li>
                  </ol>
                  <p className="text-left text-sm text-gray mt-2">
                    A Decathlon reserva-se o direito de realizar promoções
                    específicas para cada canal de comunicação e categorias
                    especificas de produtos. Por isso, entre para o
                    <a
                      href="https://www.decathlon.com.br/clube"
                      className="text-blue font-medium"
                      target="_blank"
                      rel="noreferrer"
                    >
                      &nbsp; Clube Decathlon &nbsp;
                    </a>
                    e receba nossas ofertas exclusivas por email. Você também
                    pode, seguir nossas redes sociais para receber
                    todaspromoções vigentes.
                  </p>
                </div>
                <div className="hro">
                  <div className="av-row">
                    <div className="av-col-xs-24 px-3">
                      <h2 className="text-gray italic text-xl font-semibold text-left">
                        COMO UTILIZAR UM CUPOM DE DESCONTO?
                      </h2>
                      <p className="text-left text-sm text-gray mt-4">
                        As promoções vigentes da loja decathlon.com.br são
                        exclusivas deste canal de vendas internet e não são
                        válidas para nossas lojas físicas.
                      </p>
                      <p className="text-left text-sm text-gray mt-4 mb-2">
                        Para ativar seu cupom em seu carrinho, siga o passo a
                        passo:
                      </p>

                      <Image
                        className="p-10"
                        src="https://decathlonproqa.vtexassets.com/arquivos/cupomgif.gif"
                        alt="cupom"
                        width={526}
                        height={274}
                        loading="lazy"
                      />
                      <ol className="text-left text-sm text-gray ml-10">
                        <li className="text-xs">
                          1. No site da Decathlon, selecione o produto que quer
                          comprar;
                        </li>
                        <li className="text-sm">
                          2. Adicione no seu Carrinho de Compra;
                        </li>
                        <li className="text-sm">
                          3. Acesse o checkout (antes de selecionar a entrega);
                        </li>
                        <li className="text-sm">
                          4. No campo, clique em "Inserir cupom de desconto";
                        </li>
                        <li className="text-sm">
                          5. Insira o código do seu cupom então;
                        </li>
                        <li className="text-sm">
                          6. Seu cupom será ativado caso a promoção esteja
                          validada.
                        </li>
                      </ol>
                      <p className="text-sm text-gray mt-4">
                        <strong>Atenção:</strong> o desconto só é aplicado no
                        momento do checkout.
                      </p>
                      <p className="text-sm text-gray mt-2">
                        Assim que o desconto for ativado, ele aparecerá no seu
                        carrinho. Confirme a compra e aproveite seu novo
                        produto!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CupomDesconto
